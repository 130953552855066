import * as React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"

import {LogoWithText, Spacing} from "../components/base"
import SEO from "../layouts/seo"

import "./auth-layout.scss"

/**
 * @typedef {object} AuthLayoutProps
 * @property {string=} bannerTitle
 * @property {string=} seoTitle
 * @property {string} bannerImg
 * @property {number=} bannerImgWidth
 * @property {number=} bannerImgHeight
 */

/**
 * @type {React.FunctionComponent<AuthLayoutProps>}
 * @param {AuthLayoutProps} props
 */
const AuthLayout = ({
  bannerTitle,
  bannerImg,
  seoTitle = "Sign up",
  bannerImgWidth = 460,
  bannerImgHeight = 460,
  ...props
}) => {
  return (
    <div className="auth-layout-container">
      <SEO title={seoTitle} />
      <div className="auth-layout-left-area-banner ">
        <div>
          <Link to="/" tabIndex="-1">
            <LogoWithText />
          </Link>
          <Spacing spacing="xxl-4" />
          <h1 className="title">{bannerTitle}</h1>
        </div>
        <figure>
          <img
            src={bannerImg}
            alt={bannerImg}
            width={bannerImgWidth}
            height={bannerImgHeight}
          />
        </figure>
      </div>

      {/* right area*/}
      <div className="auth-layout-right-area-scrollable">
        <div {...props} />
      </div>
    </div>
  )
}

AuthLayout.propTypes = {
  bannerTitle: PropTypes.string,
  bannerImg: PropTypes.string.isRequired,
}

AuthLayout.defaultProps = {
  bannerTitle:
    "Show your status code to the gate and protect everyone’s health.",
}

export default AuthLayout
