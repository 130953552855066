import {useState, useEffect} from "react"

const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args))

/** @type {ValidatorFn} */
const defaultValidator = () => undefined

/** @type {ShouldDisplayErrorFn} */
const defaultShouldDisplayErrorFn = ({hasBlurred, error}) => hasBlurred

/**
 * @param {{
 *   defaultValue?: string,
 *   validator?: ValidatorFn,
 *   shouldDisplayError?: ShouldDisplayErrorFn,
 *   validatorProps?: any,
 * }} param0
 */
export function useInput({
  defaultValue = "",
  validator = defaultValidator,
  shouldDisplayError = defaultShouldDisplayErrorFn,
  validatorProps = {},
}) {
  const [text, setText] = useState(defaultValue)
  const onChangeHandler = e => setText(e.target.value)

  useEffect(() => {
    setText(defaultValue)
  }, [defaultValue])

  // handle validate after blur
  const [hasBlurred, setHasBlurred] = useState(false)
  const onBlurHandler = () => {
    setHasBlurred(true)
  }

  let errorMsg = validator({
    text,
    ...validatorProps,
  })

  /**
   * @param {TextFieldProps} props
   * @returns {TextFieldProps}
   */
  const getProps = (props = {}) => {
    const {onBlur, onChange, error, ...inputProps} = props
    return {
      value: text,
      onChange: callAll(onChange, onChangeHandler),
      error:
        error ||
        (shouldDisplayError({hasBlurred, error: errorMsg, text})
          ? errorMsg
          : undefined),
      // error || errorMsg || undefined,
      onBlur: callAll(onBlur, onBlurHandler),
      ...inputProps,
    }
  }

  return {
    value: text,
    setText,
    error: errorMsg,
    getProps,
  }
}

/** @typedef {(param: {text: string }) => (string | undefined)} ValidatorFn */
/** @typedef {(param: {text: string, hasBlurred: boolean, error?: string }) => boolean} ShouldDisplayErrorFn */
/** @typedef {import('../components/base/text-field').TextFieldProps} TextFieldProps */
