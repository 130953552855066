export function emailValidator({text: email}) {
  if (!email) {
    return "Please provide your email."
  }
  let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  if (!reg.test(email)) {
    return "Hmm, that email address doesn't look right."
  }
  return undefined
}
